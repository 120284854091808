body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.map-container {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}

.wrapper {
  display: grid;
  width: 100%;
  padding: "6px";
  grid-template-columns: auto 1fr;
  grid-auto-rows: minmax(20px, auto);
}
.banner-image {
  grid-column: 1;
  grid-row: 1 / span 2;
}
.banner-title {
  grid-column: 2;
  padding-left: 6px;
  padding-top: 6px;
  padding-bottom: 0px;
  grid-row: 1;
  text-align: 'left';
  background-color: #b7b79a;
}
.banner-subtitle {
  grid-column: 2;
  grid-row: 2;
  padding-left: 6px;
  text-align: 'left';
  background-color: #b7b79a;
}

.nav-selected {
  width: 600
}

.nav-unselected {
  width: 100
}

#home-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 6px;
}

#home-left{
  min-width: 60vw;
  flex: 1;
}

#home-right{
  min-width: 250px;
  flex: 1;
}

.about-left{
  min-width: 50vw;
  flex: 1;
}

.about-right{
  min-width: 250px;
  flex: 1;
}

